* {
    padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 14px;
}

body {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

header {
  background-color: #141414;
  height: 162px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.header-main {

  height: 450px;
  max-width: 1200px;
  width: 100%;
  color: #fff;
  padding: 60px;
}



.pages-header {
  max-width: 1150px;
  width: 100%;
  margin: 0 30px 0 30px;
}



.custom-link {
  text-decoration: none;
}

.custom-link a:hover {
  color: #F0F0F0;
}

.custom-link a {
  text-decoration: none;
}

.flex-container {
  display: flex;
  max-width: 992px;
  margin: auto;
  width: 100%;
  min-width: 320px;
  justify-content: space-between;
  min-height: calc(100vh - 290px);
}

.flex-container2 {
  display: flex;
  max-width: 992px;
  margin: auto;
  width: 100%;
  min-width: 320px;
  justify-content: center;
}

header .list-menu {
  width: 100%;
  max-width: 300px;
}

.list-menu {
  width: 100%;
  max-width: 400px;
}


header .home-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

header .home-title2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-menu li a {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 300;
  color: #f3f3f3;
}

.list-menu a:hover {
  font-weight: 700;
}

.retangulo {
  
  border: 1px solid #262626;
  height: 620px;
  box-shadow: 1px 1px 4px rgba(24, 24, 24, 0.404);
  

}

#c-menu {
  margin-top: 10px;
  margin-bottom: -60px;
  border: 3px solid #262626;
}

#c-menu p {
  font-family: 'Montserrat';
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
}


#c-menu p:hover {
  font-weight: 700;
  color: #262626;
}

#c-menu a {
  min-width: 327px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#c-menu a:hover {
  background-color: #F0F0F0;
  color: #262626;
}

.container-about a {
  background-color: #262626;
  color: #F0F0F0;
}

.container-projects {
  margin-left: 10px;
  margin-right: 10px;
}

.container-projects a {
  background-color: #262626;
  color: #F0F0F0;
}

.container-contact a {
  background-color: #262626;
  color: #F0F0F0;
}

.container-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



ul li {
  list-style: none;
}



.flex-container-st {
  background-color: #F0F0F0;
  height: 40px;
  width: 100%;
  color: #262626;
  justify-content: center;
}



.projects ul {
  display: flex;
  justify-content: center;

}


.list-submenu {
  font-family: 'Montserrat';
  list-style: square;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  
}

.flex-container-project {
  margin-top: 30px;
  width: 100%;
  margin: 30px auto auto;
  min-width: 350px;
  max-width: 350px;
}

.flex-container-projects {
  margin-top: 30px;
  width: 100%;
  margin: 30px auto 0 auto;
  max-width: 1200px;
}




.img-project img {
  height: 20rem;
  width: 20rem;
  margin: 20px 0px 15px 0px;
  
}

.name-title p {

  font-family: 'Montserrat';
  font-size: 30px;
  margin-bottom: 15px;
  margin-top: 5px;
  font-weight: 600;
}

.desc-project {

  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-size: 18px;
  text-align: justify;
  min-width: 300px;
  max-width: 300px;
  min-height: 135px;
  max-height: 135px;
  ;

}

.flex-container-features {
  margin: 0 auto 0 auto;
  max-width: 1200px;
}

.title-var p {
  font-family: 'Montserrat';
  font-size: 25px;
  margin: 30px 0 30px 0;
  font-weight: 700;
  letter-spacing: .7rem;
}

.title-feature p {
  font-family: 'Montserrat';
  font-size: 24px;
  margin: 0px 0 30px 0;
  font-weight: 500;
}

.data-project {
  margin-bottom: 30px;
}

.title-sup a {
  font: italic 300 15rem 'Montserrat';
  font-size: 16px;
  margin: 15px 30px 0px 200px;
  font-weight: 600;
  text-decoration: none;
}

.title-sup p {
  font: italic 300 15rem 'Montserrat';
  font-size: 16px;
  margin: 15px 0 0px 30px;
  font-weight: 300;
}

.title-sup-m a {
  font: italic 300 15rem 'Montserrat';
  font-size: 16px;
  margin: 15px 0 0 0;
  font-weight: 600;
  text-decoration: none;
  color: #262626;
  text-align: center;
}

.title-sup-m p {
  font: italic 300 15rem 'Montserrat';
  font-size: 16px;
  margin: 5px 0 25px 5px;
  font-weight: 300;
}

.data-project-m {
  max-width: 1140px;
  width: 100%;
  margin-bottom: 30px;
}

.desc-feature {
  margin: 0px 30px 10px 30px;
}

.desc-feature p {
  font-family: 'Montserrat';
  font-size: 18px;
  text-align: justify;
  margin-bottom: 30px;
}

.img-feature1 img {
  max-height: 25rem;
  max-width: 25rem;
  margin: 0 20px 30px 20px;
}


.img-sec-main img {
  max-height: 40rem;
  margin: 0 60px 50px 26px;
  box-shadow: 1px 1px 10px rgba(24, 24, 24, 0.404);
}


.img-sec-feature {
  max-width: 1140px;
  width: 100%;
  margin-bottom: 30px
}

.img-sec-feature img {
  max-height: 30rem;
  box-shadow: 1px 1px 10px rgba(24, 24, 24, 0.404);
}

.img-sec-feature1 img {
  max-height: 30rem;
  margin: 0 30px 30px 30px;
  box-shadow: 1px 1px 10px rgba(24, 24, 24, 0.404);
}

.sec-release {
  max-width: 550px;
}


footer {
  background-color: #141414;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 70px;
  margin-bottom: auto;
}

.copyright p {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  color: #f3f3f3;
}

/* ABOUT */

.about {
  margin: 30px auto 0 auto;
  max-width: 1200px;
  min-height: calc(100vh - 320px);
}

.about-text {
  margin-left: 30px;
  margin-right: 30px;
}

.title-name p {
  font-family: 'Montserrat';
  font-size: 40px;
  font-weight: 600;
  margin: 25px 0 0 29px;
}

.subtitle-name p {
  font-family: 'Montserrat';
  font-size: 24px;
  
  margin-bottom: 25px;
  font-weight: 400;
  margin-left: 31px;

}

.desc-text p {
  font-family: 'Montserrat';
  font-size: 18px;
  text-align: justify;
  line-height: 1.4;
}

.photo-nft-brasil {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.photo-nft-brasil img {
  height: 27rem;
}


.photo-nft-brasil2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: 30px;
}

.photo-nft-brasil2 img {
  height: 27rem;
  margin: 0 10px 0 10px;
}

.photo-about {
  display: flex;
  align-items: center;
  margin: 0 10px 0 30px
}

.photo-about img {
  height: 27rem;
}

.text-cont p {
  font-family: 'Montserrat';
  font-size: 18px;
  text-align: justify;
  line-height: 1.4;

}



.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Coloca o GIF atrás do conteúdo */
}

.background-gif {
  width: 100%;
  /* Para garantir que o GIF ocupe toda a largura */
  height: 100%;
  /* Para garantir que o GIF ocupe toda a altura */
  object-fit: cover;
  /* Redimensionar o GIF para cobrir todo o elemento */
}


.img-feature1 {
  width: 100%;
}

/* CONTACT */

.contact {
  max-width: 1200px;
  margin: 10px auto 0 auto;
  min-height: calc(100vh - 300px);
}

.form-contact {
  width: 100%;
  max-width: 550px;
  margin: 0 120px 0 10px;
}

.space-form {
  max-width: 1200px;
  margin: 10px auto 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  width: 60vw;
}

input {
  display: flex;
  font-family: 'Montserrat';
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 400;
  padding: 12px 32px;
  border-radius: 1px;
  background-color: #ffffff;
  border: 0;
  box-shadow: 2px 2px 6px rgba(24, 24, 24, 0.204);
}

input, textarea {
  width: 100%;
  display: flex;
  font-family: 'Montserrat';
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 400;
  padding: 12px 32px;
  border-radius: 1px;
  background-color: #ffffff;
  border: 0;
  box-shadow: 2px 2px 6px rgba(24, 24, 24, 0.204);
}

textarea {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  column-count: initial !important;
  height: 184px;
}

form button {
  background-color: #262626;
  color: #fff;
  width: 9vw;
  height: 7vh;
  border-radius: 30px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 700;
}

.title-send-msg p {
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
}

.sent-msg img {
  margin: 0 0 40px 0;
  width: 23rem;
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }

  5% {
    transform: translate(-2px, -2px);
  }

  10% {
    transform: translate(2px, -2px);
  }

  15% {
    transform: translate(-2px, 2px);
  }

  20% {
    transform: translate(2px, 2px);
  }

  25% {
    transform: translate(0);
  }

  100% {
    transform: translate(0);
  }
}


.home-title-play-an {
  font-family: 'Montserrat';
  font-size: 30px;
  font-weight: 900;
  color: #f3f3f3;
  font-size: 36px;
  position: relative;
  animation: glitch 1s infinite;
  animation-direction: alternate;
  animation-timing-function: steps(4, start);
}

.home-title-gen-an {

  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 100;
  color: #f3f3f3;
  letter-spacing: .5rem;
  position: relative;
  animation: glitch 0.8s infinite;
  animation-direction: alternate;
  animation-timing-function: steps(4, start);
}


.home-title-play {
  font-family: 'Montserrat';
  font-size: 30px;
  font-weight: 700;
  color: #f3f3f3;
  font-size: 36px;
}

.home-title-gen {

  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 300;
  color: #f3f3f3;
  letter-spacing: .5rem;

}



.modern-menu {
  list-style: none;
  display: flex;
  padding: 0;
}

.modern-menu li {
  margin: 0 20px;
  position: relative;
}

.modern-menu a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  transition: color 0.3s ease;
}


.modern-menu a:hover {
  color: #f6f6f6;
  transform: scale(1.1);
}


.modern-menu a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f6f6f6;
  transition: width 0.3s ease;
}

.modern-menu li:hover a::before {
  width: 100%;
}






/* ------------------------- MEDIA QUERIES --------------------------- */

@media (max-width: 399.99px) {
  .img-project img {
    height: 20rem;
    width: 20rem;
  }

  .img-feature1 img {
    height: 20rem;
    width: 20rem;
  }

  .img-sec-main img {
    max-height: 30rem;
    margin: 0;
  }

  .img-sec-feature img {
    max-height: 30rem;
    margin: 0 0 30px 0;
  }

  .img-sec-feature1 img {
    max-height: 30rem;
    margin: 0 0 30px 0;
  }

  .title-var p {
    letter-spacing: .1rem;
  }

  form button {
    width: 35vw;
    height: 7vh;
  }

  .list-menu {
    width: 100%;

    max-width: 300px;
  }

  .header-main {
    flex-direction: column;
    justify-content: space-around;
  }

  .home-title-gen-an {
    letter-spacing: .2rem;
  }

  .list-menu ul {
    width: 100%;
    max-width: 300px;
    justify-content: center;
  }

  .list-menu ul li {
    width: 100%;
    text-align: center;
  }

  .list-menu ul li a {
    width: 100%;
    max-width: 50px;
    justify-content: space-between;
  }

  .list-menu ul {
    padding-left: 0;
  }

  .img-project img {
    height: 18rem;
    width: 18rem;
    margin: 20px 0px 15px 0px;
    box-shadow: 1px 1px 10px rgba(24, 24, 24, 0.404)
  }

  .img-project {
    flex-direction: column;
  }

  .flex-container-project {
    margin-top: 30px;
    width: 100%;
    margin: 30px 20px auto;
    min-width: 340px;
    max-width: 30px;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .flex-container-projects {
    justify-content: center;
  }


  .desc-project {
    margin-bottom: 15px;
    min-width: 300px;
    max-width: 300px;
    font-size: 17px;
  }

  .name-title {
    margin: 10px 15px 10px 15px;
    font-size: 29px;
  }

  
  .photo-nft-brasil img {
    height: 16rem;
  }
    
  .photo-nft-brasil2 img {
    height: 16rem;
    margin: 0 10px 20px 10px;
  }

  form {
    width: 84vw;
  }

}

@media (max-width: 575.98px) {
  .page-title {
    margin-bottom: 10px;
  }

  .home-title-gen {
    display: none;
  }

  .sent-msg img {
    display: none;
  }

  .list-submenu li a {
    font-size: 13px;
  }

  .title-sup p {
    margin: 30px 0 0 0;
  }

  .title-sup a {
    margin: 30px 30px 0 0;
  }

  .container-menu {
    display: flex;
    flex-direction: column;
  }

  #c-menu a {
    min-width: 100vw;
    min-height: 23vh;
    margin: 10px 0 0 0;
  }

  #c-menu p {
    margin: 0;

  }

  .container-projects {
    margin: 3px 0 3px 0;
  }

  #c-menu {
    margin-bottom: 0;
    border: 0;
  }

  
}

@media (min-width: 400px) and (max-width: 575.98px) {
  .img-project img {
    height: 25rem;
    width: 25rem;
  }

  .img-feature1 img {
    height: 25rem;
    width: 25rem;
  }

  .title-var p {
    letter-spacing: .1rem;
  }

  form button {
    width: 25vw;
    height: 7vh;
  }

  .page-title {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

  }

  .header-main {
    flex-direction: column;
    justify-content: space-around;
  }

  .list-menu ul {
    padding-left: 0;
  }

  .img-project img {
    height: 18rem;
    width: 18rem;
    margin: 20px 0px 15px 0px;
    box-shadow: 1px 1px 10px rgba(24, 24, 24, 0.404)
  }

  .img-project {
    flex-direction: column;
  }

  .flex-container-project {
    margin-top: 30px;
    width: 100%;
    margin: 30px 20px auto;
    min-width: 370px;
    max-width: 30px;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .flex-container-projects {
    justify-content: center;
  }


  .desc-project {
    margin: 0;
    min-width: 340px;
    max-width: 340px;
    font-size: 17px;
  }

  .name-title {
    margin: 10px 15px 10px 15px;
    font-size: 29px;
  }


  .photo-nft-brasil img {
    height: 18rem;
  }
  

  
  .photo-nft-brasil2 img {
    height: 18rem;
    margin: 0 10px 0 10px;
  }

  form {
    width: 80vw;
  }

}


@media (min-width: 575.98px) and (max-width: 767.98px) {
  .img-project img {
    height: 25rem;
    width: 25rem;
  }

  .img-feature1 img {
    height: 37rem;
    width: 37rem;
  }

  .title-sup p {
    margin: 30px 0 0 0;
  }

  .title-sup a {
    margin: 30px 30px 0 300px;
  }

  #c-menu a {
    min-width: 170px;
  }

  form button {
    width: 18vw;
    height: 7vh;
  }

  .data-project-m {
    max-width: 550px;
  }

  .header-main {
    flex-direction: column;
    justify-content: space-around;
  }

  .list-menu ul {
    padding-left: 0;
  }

  .list-menu {
    margin-top: 5px;
  }

  

  .home-title-gen {
    display: none;
  }


  .img-project img {
    height: 18rem;
    width: 18rem;
    margin: 20px 0px 15px 0px;
    box-shadow: 1px 1px 10px rgba(24, 24, 24, 0.404)
  }

  .img-project {
    flex-direction: column;
  }

  .flex-container-project {
    margin-top: 30px;
    width: 100%;
    margin: 30px 20px auto;
    min-width: 480px;
    max-width: 30px;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .flex-container-projects {
    justify-content: center;
  }


  .desc-project {
    margin: 0;
    min-width: 350px;
    max-width: 350px;
    font-size: 17px;
  }

  .name-title {
    margin: 10px 15px 10px 15px;
    font-size: 29px;
  }
 
}


@media (max-width: 767.98px) {
  .gen-2 {
    display: block;
  }

  .sent-msg img {
    display: none;
  }

  .form-contact {
    margin: 0;
  }
}

@media (min-width: 767.98px) {
  .title-send-msg p {
    display: none;
  } 
}


@media (min-width: 767.98px) and (max-width: 991.98px) {
   
  .list-menu ul {
    width: 100%;
    max-width: 300px;
  }


  .img-feature1 img {
    max-height: 23rem;
    max-width: 23rem;
  }

  .img-sec-feature img {
    max-height: 30rem;
    margin: 0 0 30px 10px;
  }

  .title-sup p {
    margin: 15px 0 0 0;
  }

  .title-sup a {
    margin: 15px 30px 0 490px;
  }

  #c-menu a {
    min-width: 230px;
  }

  form button {
    width: 12vw;
    height: 7vh;
  }

  .data-project-m {
    max-width: 730px;
  }

  .home-title-gen-an {
    letter-spacing: .4rem;
  }


  .header-main {

    justify-content: space-between;

  }

  .img-project img {
    height: 18rem;
    width: 18rem;
    margin: 20px 0px 15px 0px;
    box-shadow: 1px 1px 10px rgba(24, 24, 24, 0.404)
  }

  .img-project {
    flex-direction: column;
  }

  .flex-container-project {
    margin-top: 30px;
    width: 100%;
    margin: 30px 20px auto;
    min-width: 330px;
    max-width: 30px;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .flex-container-projects {
    justify-content: center;
  }


  .desc-project {
    margin: 10px 15px 25px 15px;
    min-width: 280px;
    max-width: 280px;
    font-size: 17px;
  }

  .name-title {
    margin: 10px 15px 10px 15px;
    font-size: 29px;
  }

  .form-contact {
    margin: 0 34px 0 10px;
  }

  form {
    width: 53vw;
  }
}


@media (max-width: 991.98px) {
  .title-name p {
    margin: 10px 0 0 0;
  }

  .subtitle-name p {
    margin-left: 0px;
    margin-top: -10px;
  }

  .photo-about {
    margin: 0;
  }

  .photo-about img {
    height: 18rem;
  }

   .desc-feature {
    margin: 0px 15px 10px 15px;
  }

   .sent-msg img {
    width: 19rem;
  }

  .img-project img {
  
  }

  .sec-release {
    max-width: 992px;
  }

  .img-sec-main img {
    margin: 0
  }
}

@media (min-width: 991.99px) and (max-width: 1200px) {

 
  .img-feature1 img {
    max-height: 32rem;
    max-width: 32rem;
  }

  #c-menu a {
    min-width: 300px;
  }

  .data-project-m {
    max-width: 930px;
  }

  .header-main {
    justify-content: space-between;
  }

  .flex-container-project {
    margin-top: 30px;
    width: 100%;
    margin: 30px auto auto;
    min-width: 330px;
    max-width: 30px;
  }


  


  .form-contact {
    margin: 0 34px 0 10px;
  }
  
}

@media (min-width: 1200px) {

  
  .img-feature1 img {
    max-height: 32rem;
    max-width: 32rem;
  }

  #c-menu a {
    min-width: 300px;
  }

  .data-project-m {
    max-width: 930px;
  }

  .header-main {
    justify-content: space-between;
  }
}